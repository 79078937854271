var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "overview-filters align-items-start" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("filter-set", {
                attrs: {
                  name: _setup.FilterDataName.Meters,
                  filters: _setup.filters,
                },
                on: {
                  "filter-search": _setup.handleFilterSearch,
                  "filters-changed27": _setup.handleFiltersChanged,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "EU-Register",
                          params: {
                            registerId: item.id.toString(),
                            addModus: false,
                          },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-table "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.select`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary my-1",
                      attrs: { dark: "", title: "Kiezen" },
                      on: {
                        click: function ($event) {
                          return _setup.selectedRegister(item.id)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", left: "" } }, [
                        _vm._v("mdi-checkbox-marked-circle-outline"),
                      ]),
                      _vm._v(" Kiezen "),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.isIndicative`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-center": "" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "ma-0 pa-0 center",
                        attrs: { disabled: "", "hide-details": "" },
                        model: {
                          value: item.isIndicative,
                          callback: function ($$v) {
                            _vm.$set(item, "isIndicative", $$v)
                          },
                          expression: "item.isIndicative",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.valFrom`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.valFrom)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.valTill`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.valTill)) + " "
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }